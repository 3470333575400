import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { appList, appContent } from "config/content";
import AppIcon from "components/app-icon";

const HomePage = () => {
  return (
    <div>
      <div className={styles.contentHeader}>Mobile Apps</div>
      <div className={styles.contentContainer}>
        <div className={styles.innerContainer}>
          {appList.map((appKey, i) => {
            return (
              <Link to={appContent[appKey].path} key={i}>
                <AppIcon
                  imageUrl={appContent[appKey].appIcon}
                  name={appContent[appKey].name}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
