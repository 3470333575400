import React from "react";
import ScrollToTop from "components/scroll-to-top";
import { IAppContent } from "config/content";
import styles from "./index.module.css";

interface IDetail {
  content: IAppContent;
}

const Detail = ({ content }: IDetail) => {
  const { name, logo, description, screenshots, link, social } = content;

  const { appStore, playStore } = link;

  const assetPath = `assets`;

  return (
    <>
      <ScrollToTop />
      {logo ? (
        <div className={styles.appName}>
          <img src={logo} alt={name} />
        </div>
      ) : (
        <div className={styles.appName}>{name}</div>
      )}
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>{description}</div>
      </div>

      <div className={styles.thumbnailContainer}>
        <div className={styles.innerContainer}>
          {screenshots.map((imgUrl, i) => (
            <div key={i} className={styles.thumbnail}>
              <img src={imgUrl} alt={name} />
            </div>
          ))}
        </div>
      </div>

      {(appStore || playStore) && (
        <div className={styles.badgeContainer}>
          {appStore && (
            <div className={styles.badge}>
              <a href={appStore} target="_blank" rel="noopener noreferrer">
                <img
                  className={styles.appStore}
                  src={`${assetPath}/badge-appstore.svg`}
                  alt={`Download ${name} at AppStore`}
                />
              </a>
            </div>
          )}
          {playStore && (
            <div className={styles.badge}>
              <a href={playStore} target="_blank" rel="noopener noreferrer">
                <img
                  className={styles.playStore}
                  src={`${assetPath}/badge-googleplay.png`}
                  alt={`Download ${name} at PlayStore`}
                />
              </a>
            </div>
          )}
        </div>
      )}

      {social && (
        <div className={styles.socialContainer}>
          {social.map(({ name, url }, i) => (
            <div className={styles.socialIcon} key={i}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Detail;
