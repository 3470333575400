import React from "react";
import { Link } from "react-router-dom";
import styles from "./sub.module.css";

interface ISubNav {
  label: string;
  path: string;
}

interface ISubFooter {
  navList: ISubNav[];
}

const SubFooter = ({ navList = [] }: ISubFooter) => {
  return (
    <div className={styles.subFooterContainer}>
      {navList.map(({ label, path }, i) => (
        <span key={i}>
          <Link className={styles.link} to={path}>
            {label}
          </Link>
        </span>
      ))}
    </div>
  );
};

export default SubFooter;
