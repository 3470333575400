import React from "react";
import styles from "./index.module.css";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <span className={styles.text}>
        Copyright © 2020 AFTERWORKCODER. All rights reserved.
      </span>
    </div>
  );
};

export default Footer;
