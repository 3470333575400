interface ISocial {
  name: string;
  url: string;
}

export interface IAppContent {
  path: string;
  name: string;
  logo: string | null;
  description: string;
  appIcon: string;
  screenshots: string[];
  link: {
    appStore: string | null;
    playStore: string | null;
  };
  social?: ISocial[];
}

interface IApp {
  [appKey: string]: IAppContent;
}

const appList: string[] = ["castnow", "luckynumber", "powerball"];

const appContent: IApp = {
  castnow: {
    appIcon: "assets/castnow/app-icon.png",
    path: "/castnow",
    name: "CastNow",
    logo: "assets/castnow/logo.svg",
    description:
      "CastNow App provides you a special browser where you can search and navigate through any webpage and when video is detected in the webpage, castnow icon will be highlighted. You can either cast to chromecast the detected video or play it in your device. This app has special feature to guess higher resolution based on the pattern of the video. So you can enjoy your video with higher resolution!",

    screenshots: [
      "assets/castnow/preview-1.jpg",
      "assets/castnow/preview-2.jpg",
      "assets/castnow/preview-3.jpg",
    ],
    link: {
      appStore:
        "https://apps.apple.com/us/app/castnow-for-chromecast-stream/id1460088029",
      playStore: "",
    },
    social: [{ name: "twitter", url: "https://twitter.com/castnowapp" }],
  },
  luckynumber: {
    appIcon: "assets/luckynumber/app-icon.svg",
    path: "/luckynumber",
    name: "Lucky Number",
    logo: null,
    description:
      "Lucky Number for Mega Millions uses special algorithm to generate random numbers your lottery. Our app provides 2 different ways to generate the lucky number. If you want to quickly draw multiple numbers, you may play from Quick Draw section. Another way to play it is that, you may manually shuffle anytime and draw the number.",
    screenshots: [
      "assets/luckynumber/preview-1.png",
      "assets/luckynumber/preview-2.png",
      "assets/luckynumber/preview-3.png",
    ],
    link: {
      appStore:
        "https://apps.apple.com/us/app/lucky-number-for-mega-millions/id1501000893",
      playStore:
        "https://play.google.com/store/apps/details?id=com.afterworkcoder.luckynumber",
    },
  },
  powerball: {
    appIcon: "assets/luckynumber/app-icon.svg",
    path: "/powerball",
    name: "Powerball Shuffler",
    logo: null,
    description:
      "Powerball Shuffler uses special algorithm to generate random numbers your lottery. Our app provides 2 different ways to generate the lucky number. If you want to quickly draw multiple numbers, you may play from Quick Draw section. Another way to play it is that, you may manually shuffle anytime and draw the number. Best luck!",
    screenshots: [
      "assets/powerball/preview-1.png",
      "assets/powerball/preview-2.png",
      "assets/powerball/preview-3.png",
    ],
    link: {
      appStore: null,
      playStore: null,
    },
  },
};

export { appList, appContent };
