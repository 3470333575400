import React from "react";
import styles from "./logo.module.css";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <span className={styles.light}>AFTERWORK</span>
      <span className={styles.bold}>CODER</span>
    </div>
  );
};

export default Logo;
