import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "components/header";
import Footer from "components/footer";
import HomePage from "pages/homepage";
import CastNow from "pages/castnow";
import LuckyNumber from "pages/luckynumber";
import PowerballShuffler from "pages/powerball-shuffler";

const Application = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/castnow">
          <CastNow />
        </Route>
        <Route path="/luckynumber">
          <LuckyNumber />
        </Route>
        <Route path="/powerball">
          <PowerballShuffler />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};

export default Application;
