import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PrivacyPolicy from "pages/privacy-policy";
import Detail from "pages/detail";
import SubFooter from "components/footer/sub";
import { appContent } from "config/content";

const PAGEKEY = "powerball";

const PowerballShuffler = () => {
  let match = useRouteMatch();
  const subFooter = [
    { label: "Privacy Policy", path: `/${PAGEKEY}/privacy-policy` },
  ];
  return (
    <>
      <Switch>
        <Route path={`${match.path}/privacy-policy`}>
          <PrivacyPolicy appName={appContent[PAGEKEY].name} />
        </Route>
        <Route path={match.path}>
          <Detail content={appContent[PAGEKEY]} />
        </Route>
      </Switch>
      <SubFooter navList={subFooter} />
    </>
  );
};

export default PowerballShuffler;
