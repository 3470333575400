import React from "react";
import styles from "./index.module.css";

interface IAppIcon {
  imageUrl: string;
  name: string;
}

const AppIcon = ({ imageUrl, name }: IAppIcon) => {
  return (
    <>
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt={name} />
      </div>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{name}</h2>
      </div>
    </>
  );
};

export default AppIcon;
